import { useEffect, useMemo, useState } from 'react';
import { ExamApi, useApiConfig } from 'api';
import { useNavigate, useParams } from 'react-router-dom';

export interface QuestionType {
  id: number;
  QUESTION: string;
  ANSWER: string;
}

const useExamDetails = () => {
  const [notes, setNotes] = useState('');
  const [examName, setExamName] = useState('');
  const [questions, setQuestions] = useState<[QuestionType] | []>([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState<string>('0');

  const [questionLoading, setQuestionLoading] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('1');

  const [errorMessage, setErrorMessage] = useState('');
  const [alert, setAlert] = useState('');
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

  const { id } = useParams<{ id: string }>();
  const { config } = useApiConfig();
  const examApi = useMemo(() => new ExamApi(config), [config]);
  const navigate = useNavigate();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert('');
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    if (!id) {
      setErrorMessage('No exam id found');
      return;
    }
    const fetchNotes = async () => {
      setNotesLoading(true);
      examApi
        .retrieveExam(id.toString())
        .then(res => {
          setNotes(res.data.notes || '');
          setExamName(res.data.exam_name || '');
          setNumberOfQuestions(res.data.number_of_questions?.toString() || '0');
        })
        .catch(e => {
          setErrorMessage(e.message);
        })
        .finally(() => {
          setNotesLoading(false);
        });
    };
    fetchNotes();
  }, [examApi, id]);

  const deleteExam = async () => {
    if (!id) {
      setErrorMessage('No exam id found');
      return;
    }
    setNotesLoading(true);
    examApi
      .destroyExam(id.toString())
      .then(() => {
        navigate('/exams');
      })
      .catch(e => {
        setErrorMessage(e.message);
      });
  };

  const saveNotes = async () => {
    if (!id) {
      setErrorMessage('No exam id found');
      return;
    }
    setNotesLoading(true);
    examApi
      .updateExam(id.toString(), {
        notes: notes,
      })
      .then(() => {
        setAlert('Notes saved successfully');
      })
      .catch(e => {
        setErrorMessage(e.message);
      })
      .finally(() => {
        setNotesLoading(false);
      });
  };

  const fetchQuestions = async () => {
    setQuestionLoading(true);
    if (!id) {
      setErrorMessage('No exam id found');
      return;
    }
    examApi.questionsExam(id.toString()).then(res1 => {
      // @ts-ignore
      const questionData = res1.data.data;

      if (typeof questionData === 'object') {
        setQuestions(questionData);
      } else {
        setQuestions([]);
        setErrorMessage('No questions found: ' + questionData);
      }
      setQuestionLoading(false);
    });
  };

  const updateExam = async () => {
    if (!id) {
      setErrorMessage('No exam id found');
      return;
    }
    setNotesLoading(true);
    try {
      examApi
        .updateExam(id.toString(), {
          exam_name: examName,
          notes: notes,
          number_of_questions: parseInt(numberOfQuestions),
        })
        .then(() => {
          setAlert('Settings updated');
        })
        .catch(e => {
          setErrorMessage(e.message);
        })
        .finally(() => {
          setNotesLoading(false);
        });
    } catch (e) {
      setErrorMessage('Error updating exam name');
    }
  };

  return {
    notes,
    setNotes,
    questionLoading,
    notesLoading,
    questions,
    errorMessage,
    setErrorMessage,
    saveNotes,
    alert,
    setAlert,
    examName,
    setExamName,
    updateExam,
    currentTab,
    handleTabChange,
    fetchQuestions,
    numberOfQuestions,
    setNumberOfQuestions,
    confirmModalIsOpen,
    setConfirmModalIsOpen,
    deleteExam,
  };
};

export default useExamDetails;
