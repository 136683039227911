import { Paper, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ExamOverviewProps {
  title?: string;
  href?: string;
}

const ExamOverview = ({ title, href }: ExamOverviewProps) => {
  const navigate = useNavigate();
  const displayTitle = title && title.length > 20 ? `${title.substring(0, 25)}...` : title;

  return (
    <Paper
      onClick={() => {
        navigate(href || '/unknown');
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '150px',
        height: '100%',
        minHeight: '200px',
        width: '10vw',
        mt: '15px',
        '&:hover': {
          cursor: 'pointer',
        },
        mr: '15px',
        p: 3,
      }}>
      <Typography variant="h5">{displayTitle}</Typography>
    </Paper>
  );
};

export default ExamOverview;
