import { useContext, useEffect, useMemo, useState } from 'react';
import { Configuration, BaseAPI } from './schema';
import { useAuthentication, TokenContext } from './auth/useAuthentication';
import axios from 'axios';

export const baseUrl = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'debug') {
    return 'http://localhost:8000';
  } else {
    return process.env.REACT_APP_API_URL;
  }
};

const defaultConfig = new Configuration({
  basePath: baseUrl(),
});

interface ApiHookReturn<ApiObject extends typeof BaseAPI> {
  apiInstance: InstanceType<ApiObject>;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  error: string | null;
  setError: (error: string | null) => void;
}

export const useApi = <ApiObject extends typeof BaseAPI>(
  apiClass: ApiObject,
): ApiHookReturn<ApiObject> => {
  const { config } = useApiConfig();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  return {
    apiInstance: useMemo(() => new apiClass(config), [config, apiClass]) as InstanceType<ApiObject>,
    loading,
    setLoading,
    error,
    setError,
  };
};

export const useApiConfig = () => {
  const { accessToken } = useContext(TokenContext);
  const config = useMemo(() => new Configuration({ basePath: baseUrl() }), []);

  useEffect(() => {
    config.baseOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }, [accessToken, config]);

  const uploadFile = async (formData: FormData, endpoint: string) => {
    return axios.post(`${baseUrl()}/${endpoint}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken || 'noToken'}`,
      },
    });
  };

  return {
    config,
    uploadFile,
  };
};
export { useAuthentication, defaultConfig, TokenContext };
export * from './schema';
