import React from 'react';
import { signUpStyle } from 'pages/SignUp/style';
import { Alert, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import useLoginForm from './useLoginFormik';

const LoginForm = () => {
  const classes = signUpStyle();
  const { formik, authenticating, setErrorAlert, errorAlert } = useLoginForm();

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {errorAlert && (
            <Alert
              sx={{ mb: 3 }}
              data-cy="error-alert"
              severity="error"
              onClose={() => {
                setErrorAlert('');
              }}>
              {errorAlert}
            </Alert>
          )}
          <TextField
            variant="outlined"
            data-cy="email"
            fullWidth
            id="email"
            label={'Email Address'}
            name="email"
            autoComplete="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            helperText={formik.touched.email && formik.errors.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            value={formik.values.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            placeholder="Type Here"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            helperText={formik.touched.password && formik.errors.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            value={formik.values.password}
          />
        </Grid>
      </Grid>
      <LoadingButton
        loading={authenticating}
        sx={{ mt: 3 }}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onBlur={formik.handleBlur}>
        Login
      </LoadingButton>
    </form>
  );
};

export default LoginForm;
