import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import QuizIcon from '@mui/icons-material/Quiz';
import ListItem from '@mui/material/ListItem';
import { AppBar, Button, IconButton, Stack, Toolbar, useMediaQuery } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import { baseUrl, useAuthentication } from 'api';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

interface SideDrawerProps {
  renderedComponent: React.ReactNode;
  selectedRoute: string;
}

const SideDrawer = ({ renderedComponent, selectedRoute }: SideDrawerProps) => {
  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width:900px)');

  const options = [
    {
      name: 'Exams',
      route: '/exams',
      icon: <QuizIcon />,
    },
    {
      name: 'Admin',
      route: baseUrl() + '/admin',
      icon: <AdminPanelSettingsIcon />,
    },
  ];
  const { logout } = useAuthentication();

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        hideBackdrop={!isMobile ? true : false}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
          },
        }}
        variant={!isMobile ? 'permanent' : 'temporary'}
        anchor="left"
        data-testid="db-metric-logo"
        open={!isMobile || open}
        onClose={() => setOpen(false)}
        ModalProps={{ onBackdropClick: () => setOpen(false) }}>
        <Stack direction="row" m={5}>
          {/* <img src={require('../../assets/dblogo.png')} height={42} width={60} /> */}
          <Typography alignSelf={'center'} variant="h4">
            RevisionAI
          </Typography>
        </Stack>

        <List sx={{ flexGrow: 1 }}>
          {options.map(option => (
            <Link
              key={option.name}
              to={option.route}
              style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItem disablePadding>
                <ListItemButton selected={option.route === selectedRoute}>
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  <ListItemText primary={option.name} />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>

        <Box bottom="0px" mb={5} style={{ width: '90%' }} alignSelf={'center'}>
          <Button onClick={logout} variant="text" fullWidth={true} startIcon={<LogoutIcon />}>
            <Typography>Sign Out</Typography>
          </Button>
        </Box>
      </Drawer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          ml: isMobile ? 0 : '240px',
        }}>
        <AppBar position="relative" sx={{ height: '70px', display: { sm: 'bock', md: 'none' } }}>
          <Toolbar sx={{ height: '100%' }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setOpen(!open)}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              {options.filter(option => option.route === selectedRoute)[0].name}
            </Typography>
          </Toolbar>
        </AppBar>
        {renderedComponent}
      </Box>
    </Box>
  );
};

export default SideDrawer;
