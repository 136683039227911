import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { render } from '@testing-library/react';

export function validateName(name: string): boolean {
  // Only accept alphabetical characters and spaces
  const regex = /^[a-zA-Z\s]*$/;

  // Check if the name matches the regular expression
  return regex.test(name);
}

export function validateEmail(email: string): boolean {
  // Only accept valid email format
  const regex = /\S+@\S+\.\S+/;

  // Check if the email matches the regular expression
  return regex.test(email);
}

export const CustomRender = (component: React.ReactElement) => {
  render(
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BrowserRouter>{component}</BrowserRouter>
    </LocalizationProvider>,
  );
};
