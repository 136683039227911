import React, { useState } from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { HashRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TokenContext } from 'api';
import Theme from './theme';
import Routes from './routes';

function App() {
  const [accessToken, setAccessToken] = useState<string>('');
  const [refreshToken, setRefreshToken] = useState<string>('');

  return (
    <HashRouter basename="/">
      <ThemeProvider theme={Theme}>
        <div className="App">
          <CssBaseline />
          <TokenContext.Provider
            value={{
              accessToken,
              setAccessToken,
              refreshToken,
              setRefreshToken,
            }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Routes />
            </LocalizationProvider>
          </TokenContext.Provider>
        </div>
      </ThemeProvider>
    </HashRouter>
  );
}

export default App;
