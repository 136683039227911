import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface ConfirmModalProps {
  isOpen: boolean;
  close: () => void;
  action: () => void;
  title: string;
  description: string;
  buttonText?: string;
  isDanger?: boolean;
}

const ConfirmModal = ({
  isOpen,
  close,
  action,
  title,
  description,
  buttonText,
  isDanger,
}: ConfirmModalProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title || 'No Title'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description || 'No Description'}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={close}>Cancel</Button>
        <Button
          variant="outlined"
          color={isDanger ? 'error' : 'primary'}
          sx={{ color: 'error', ml: 2 }}
          onClick={action}>
          {buttonText || 'No Button Text'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
