import { LoadingButton } from '@mui/lab';
import { Box, TextField, Button } from '@mui/material';
import React from 'react';

interface ExamSettingsProps {
  examName: string;
  setExamName: (examName: string) => void;
  numberOfQuestions: string;
  setNumberOfQuestions: (numberOfQuestions: string) => void;
  updateExam: () => void;
  deleteExam: () => void;
}

const ExamSettings = ({
  examName,
  setExamName,
  numberOfQuestions,
  setNumberOfQuestions,
  updateExam,
  deleteExam,
}: ExamSettingsProps) => (
  <Box
    sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', alignItems: 'left' }}>
    <TextField
      id="exam_name"
      label="Exam Name"
      value={examName}
      onChange={e => setExamName(e.target.value)}
      style={{
        border: 'none',
        outline: 'none',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        width: '100%',
        textAlign: 'center',
      }}
      sx={{ mb: 2 }}
    />
    <TextField
      id="number_of_questions"
      label="Number of Questions"
      value={numberOfQuestions}
      type="number"
      onChange={e => setNumberOfQuestions(e.target.value)}
      style={{
        border: 'none',
        outline: 'none',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        width: '100%',
        textAlign: 'center',
      }}
    />
    <Button
      variant="outlined"
      color="error"
      sx={{ maxWidth: '250px', mb: 4, mt: 2 }}
      onClick={deleteExam}>
      Delete Exam
    </Button>
    <LoadingButton loading={false} onClick={updateExam} variant="contained" color="primary">
      Update
    </LoadingButton>
  </Box>
);

export default ExamSettings;
