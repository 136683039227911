import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ExamApi, Exam, useApi } from 'api';
import ExamOverview from 'component/examOverview/examOverview';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Exams = () => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [exams, setExams] = React.useState<Exam[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:900px)');
  const { apiInstance: examApi } = useApi<typeof ExamApi>(ExamApi);

  const createExam = () => {
    setLoading(true);
    examApi
      .createExam({ exam_name: 'Click To Change', notes: 'Your Notes Here' })
      .then(res => {
        navigate(`/exams/${res.data.id}`);
      })
      .catch(err => {
        setErrorMessage(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    examApi.listExams().then(res => {
      // @ts-ignore
      const examData = res.data;

      if (typeof examData === 'object') {
        setExams(examData);
      } else {
        setExams([]);
        setErrorMessage('No exams found: ' + examData);
      }
      setLoading(false);
    });
  }, [examApi]);

  return (
    <Container
      sx={{
        minHeight: '100vh',
        py: 5,
      }}>
      <Box sx={{ display: 'flex', alignContent: 'start', flexDirection: 'column' }}>
        <Box>
          {!isMobile && (
            <>
              <Typography variant="h3">Exams</Typography>
              <Divider sx={{ mt: 2 }} />
            </>
          )}
          {errorMessage && (
            <Alert
              sx={{ mt: 2 }}
              severity="error"
              onClose={() => {
                setErrorMessage('');
              }}>
              {errorMessage}
            </Alert>
          )}
          {loading && <CircularProgress />}
          <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap' }}>
            {exams.map((exam: Exam) => (
              <ExamOverview key={exam.id} title={exam.exam_name} href={`/exams/${exam.id}`} />
            ))}
          </Box>
        </Box>
        <Button
          sx={{ mt: 2, ml: 0, width: '10vw', minWidth: '150px' }}
          variant="contained"
          onClick={createExam}>
          New Exam
        </Button>
      </Box>
    </Container>
  );
};

export default Exams;
