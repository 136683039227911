import React from 'react';
import { Alert, Box, Container, Snackbar, Tab, Typography } from '@mui/material';
import useExamDetails from './useExamDetail';
import ExamNotes from 'component/examNotes';
import ExamQuestions from 'component/examQuestions';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ExamSettings from 'component/examSettings';
import ConfirmModal from 'component/confirmModal';

const ExamDetails = () => {
  const {
    notes,
    setNotes,
    notesLoading,
    questionLoading,
    questions,
    errorMessage,
    setErrorMessage,
    alert,
    setAlert,
    saveNotes,
    examName,
    updateExam,
    setExamName,
    currentTab,
    handleTabChange,
    fetchQuestions,
    numberOfQuestions,
    setNumberOfQuestions,
    confirmModalIsOpen,
    setConfirmModalIsOpen,
    deleteExam,
  } = useExamDetails();

  return (
    <Container sx={{ minHeight: '100vh', py: 5 }}>
      <Box>
        <Typography variant="h3" sx={{ mb: 2 }}>
          <input
            id="exam_name"
            onBlur={updateExam}
            value={examName}
            onChange={e => setExamName(e.target.value)}
            style={{
              border: 'none',
              outline: 'none',
              fontSize: 'inherit',
              fontWeight: 'inherit',
              fontFamily: 'inherit',
              width: '100%',
              textAlign: 'center',
            }}
          />
        </Typography>
        {errorMessage && (
          <Alert
            sx={{ mt: 2 }}
            severity="error"
            onClose={() => {
              setErrorMessage('');
            }}>
            {errorMessage}
          </Alert>
        )}

        {alert && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={!!alert}
            autoHideDuration={3000}
            onClose={() => setAlert('')}
            message={alert}>
            <Alert onClose={() => setAlert('')} severity="success" sx={{ width: '100%' }}>
              {alert}
            </Alert>
          </Snackbar>
        )}

        <ConfirmModal
          isOpen={confirmModalIsOpen}
          close={() => setConfirmModalIsOpen(false)}
          action={deleteExam}
          title="Delete Exam"
          description="Are you sure you want to delete this exam? This process is NOT reversible."
          buttonText="Delete"
          isDanger
        />

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={currentTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label="Notes" value="1" />
                <Tab label="Questions" value="2" />
                <Tab label="Settings" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ExamNotes
                notes={notes}
                setNotes={setNotes}
                loading={notesLoading}
                saveNotes={saveNotes}
              />
            </TabPanel>
            <TabPanel value="2">
              <ExamQuestions
                questions={questions}
                loading={questionLoading}
                generateQuestions={fetchQuestions}
              />
            </TabPanel>
            <TabPanel value="3">
              <ExamSettings
                examName={examName}
                setExamName={setExamName}
                updateExam={updateExam}
                numberOfQuestions={numberOfQuestions}
                setNumberOfQuestions={setNumberOfQuestions}
                deleteExam={() => setConfirmModalIsOpen(true)}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Container>
  );
};
export default ExamDetails;
