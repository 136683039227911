import useAuthentication from 'api/auth/useAuthentication';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from 'utils/index';

interface formValues {
  email?: string;
  password?: string;
}

interface formErrors {
  email?: string;
  password?: string;
}

const validate = (values: formValues) => {
  const errors: Partial<formErrors> = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (!validateEmail(values?.email || '')) {
    errors.email = 'Please enter a valid email';
  }

  if (!values.password) {
    errors.password = 'Required';
  }
  return errors;
};

const useLoginForm = () => {
  const { login, authenticating, errorAlert, setErrorAlert } = useAuthentication();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: undefined,
      password: undefined,
    },
    onSubmit: ({ email, password }: formValues) => {
      if (email && password) {
        login({ email, password }).then(res => {
          if (res?.success) navigate('/exams');
          else setErrorAlert(res?.errors[0]);
        });
      }
    },
    validate,
  });

  return { formik, authenticating, setErrorAlert, errorAlert };
};

export default useLoginForm;
