/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Exam
 */
export interface Exam {
    /**
     * 
     * @type {number}
     * @memberof Exam
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    'file_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    'notes': string;
    /**
     * 
     * @type {number}
     * @memberof Exam
     */
    'number_of_questions'?: number;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    'question_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    'exam_name'?: string;
}
/**
 * 
 * @export
 * @interface TokenObtainPair
 */
export interface TokenObtainPair {
    /**
     * 
     * @type {string}
     * @memberof TokenObtainPair
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof TokenObtainPair
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface TokenRefresh
 */
export interface TokenRefresh {
    /**
     * 
     * @type {string}
     * @memberof TokenRefresh
     */
    'refresh': string;
    /**
     * 
     * @type {string}
     * @memberof TokenRefresh
     */
    'access'?: string;
}
/**
 * 
 * @export
 * @interface TokenVerify
 */
export interface TokenVerify {
    /**
     * 
     * @type {string}
     * @memberof TokenVerify
     */
    'token': string;
}

/**
 * ExamApi - axios parameter creator
 * @export
 */
export const ExamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Exam} [exam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExam: async (exam?: Exam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/exam/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyExam: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyExam', 'id', id)
            const localVarPath = `/exam/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExams: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/exam/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {Exam} [exam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateExam: async (id: string, exam?: Exam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateExam', 'id', id)
            const localVarPath = `/exam/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsExam: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('questionsExam', 'id', id)
            const localVarPath = `/exam/{id}/questions/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveExam: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('retrieveExam', 'id', id)
            const localVarPath = `/exam/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {Exam} [exam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExam: async (id: string, exam?: Exam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExam', 'id', id)
            const localVarPath = `/exam/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExamApi - functional programming interface
 * @export
 */
export const ExamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Exam} [exam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExam(exam?: Exam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Exam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExam(exam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyExam(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyExam(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listExams(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Exam>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listExams(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {Exam} [exam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateExam(id: string, exam?: Exam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Exam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateExam(id, exam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionsExam(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Exam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionsExam(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveExam(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Exam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveExam(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {Exam} [exam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExam(id: string, exam?: Exam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Exam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExam(id, exam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExamApi - factory interface
 * @export
 */
export const ExamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExamApiFp(configuration)
    return {
        /**
         * 
         * @param {Exam} [exam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExam(exam?: Exam, options?: any): AxiosPromise<Exam> {
            return localVarFp.createExam(exam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyExam(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.destroyExam(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExams(options?: any): AxiosPromise<Array<Exam>> {
            return localVarFp.listExams(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {Exam} [exam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateExam(id: string, exam?: Exam, options?: any): AxiosPromise<Exam> {
            return localVarFp.partialUpdateExam(id, exam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsExam(id: string, options?: any): AxiosPromise<Exam> {
            return localVarFp.questionsExam(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveExam(id: string, options?: any): AxiosPromise<Exam> {
            return localVarFp.retrieveExam(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this exam.
         * @param {Exam} [exam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExam(id: string, exam?: Exam, options?: any): AxiosPromise<Exam> {
            return localVarFp.updateExam(id, exam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExamApi - object-oriented interface
 * @export
 * @class ExamApi
 * @extends {BaseAPI}
 */
export class ExamApi extends BaseAPI {
    /**
     * 
     * @param {Exam} [exam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public createExam(exam?: Exam, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).createExam(exam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this exam.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public destroyExam(id: string, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).destroyExam(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public listExams(options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).listExams(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this exam.
     * @param {Exam} [exam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public partialUpdateExam(id: string, exam?: Exam, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).partialUpdateExam(id, exam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this exam.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public questionsExam(id: string, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).questionsExam(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this exam.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public retrieveExam(id: string, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).retrieveExam(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this exam.
     * @param {Exam} [exam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public updateExam(id: string, exam?: Exam, options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).updateExam(id, exam, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {TokenObtainPair} [tokenObtainPair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTokenObtainPair: async (tokenObtainPair?: TokenObtainPair, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenObtainPair, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} [tokenRefresh] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTokenRefresh: async (tokenRefresh?: TokenRefresh, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token/refresh/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRefresh, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
         * @param {TokenVerify} [tokenVerify] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTokenVerify: async (tokenVerify?: TokenVerify, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token/verify/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenVerify, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration)
    return {
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {TokenObtainPair} [tokenObtainPair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTokenObtainPair(tokenObtainPair?: TokenObtainPair, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenObtainPair>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTokenObtainPair(tokenObtainPair, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} [tokenRefresh] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTokenRefresh(tokenRefresh?: TokenRefresh, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenRefresh>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTokenRefresh(tokenRefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
         * @param {TokenVerify} [tokenVerify] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTokenVerify(tokenVerify?: TokenVerify, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenVerify>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTokenVerify(tokenVerify, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration)
    return {
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {TokenObtainPair} [tokenObtainPair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTokenObtainPair(tokenObtainPair?: TokenObtainPair, options?: any): AxiosPromise<TokenObtainPair> {
            return localVarFp.createTokenObtainPair(tokenObtainPair, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} [tokenRefresh] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTokenRefresh(tokenRefresh?: TokenRefresh, options?: any): AxiosPromise<TokenRefresh> {
            return localVarFp.createTokenRefresh(tokenRefresh, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
         * @param {TokenVerify} [tokenVerify] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTokenVerify(tokenVerify?: TokenVerify, options?: any): AxiosPromise<TokenVerify> {
            return localVarFp.createTokenVerify(tokenVerify, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     * @param {TokenObtainPair} [tokenObtainPair] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public createTokenObtainPair(tokenObtainPair?: TokenObtainPair, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).createTokenObtainPair(tokenObtainPair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {TokenRefresh} [tokenRefresh] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public createTokenRefresh(tokenRefresh?: TokenRefresh, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).createTokenRefresh(tokenRefresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     * @param {TokenVerify} [tokenVerify] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public createTokenVerify(tokenVerify?: TokenVerify, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).createTokenVerify(tokenVerify, options).then((request) => request(this.axios, this.basePath));
    }
}



