import React from 'react';
import { Box, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface ExamNotesProps {
  notes: string;
  setNotes: (notes: string) => void;
  loading: boolean;
  saveNotes: () => void;
}

const ExamNotes = ({ notes, setNotes, loading, saveNotes }: ExamNotesProps) => (
  <Box
    sx={{ display: 'flex', justifyContent: 'start', alignItems: 'left', flexDirection: 'column' }}>
    <TextField
      value={notes}
      onChange={e => setNotes(e.target.value)}
      fullWidth
      id="notes"
      label="Your Notes (Max 50 lines)"
      variant="outlined"
      multiline
      minRows={3}
      maxRows={50}
      sx={{ mb: 2 }}
    />
    <LoadingButton variant="contained" onClick={saveNotes} loading={loading}>
      Save Notes
    </LoadingButton>
  </Box>
);

export default ExamNotes;
