import React, { useEffect, useState } from 'react';
import { Typography, Snackbar } from '@mui/material';
import { signUpStyle } from 'pages/SignUp/style';
import LoginForm from 'component/loginForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthentication } from 'api';

const Login = () => {
  const [showSnack, setShowSnack] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);
  const classes = signUpStyle();
  const location = useLocation();
  const { checkAuth } = useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    const redirectIfLoggedIn = async () => {
      if (!authChecked && (await checkAuth())) {
        navigate('/exams');
      } else {
        setAuthChecked(true);
      }
    };
    redirectIfLoggedIn();
  });

  useEffect(() => {
    if (location.state) {
      const openSnackBar = location.state.openSnackBar;
      if (openSnackBar) {
        setShowSnack(true);
      }
    }
  }, [location]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSnack(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [showSnack]);

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.paper} style={{ alignItems: 'center' }}>
          <div>
            <Typography textAlign={'start'} variant="h3">
              Log in
            </Typography>
            <Typography textAlign={'start'} component="p" variant="subtitle1">
              Welcome to Revision AI
            </Typography>
          </div>
          <LoginForm />
        </div>
      </div>

      {showSnack && (
        <Snackbar
          open={showSnack}
          autoHideDuration={3000}
          message={'Unauthorized! Please login again'}
        />
      )}
    </div>
  );
};

export default Login;
