import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from 'routes/protectedRoutes';

import Login from 'pages/Login';
import ExamDetails from 'pages/ExamDetail';
import Exams from 'pages/Exams';
import { Box, Button } from '@mui/material';

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/exams/:id"
          element={
            <ProtectedRoute route="/exams">
              <ExamDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/exams"
          element={
            <ProtectedRoute route="/exams">
              <Exams />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <Box sx={{ minHeight: '100vh', py: 5 }}>
              <h1>404 Not Found</h1>
              <Button variant="contained" href="/">
                Go To Login
              </Button>
            </Box>
          }
        />
      </Routes>
    </>
  );
};

export default AppRoutes;
