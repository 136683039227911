import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface QuestionProps {
  questionText: string;
  answerText: string;
}

const Question = ({ questionText, answerText }: QuestionProps) => {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            <Typography variant="h5">{questionText}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', justifyContent: 'left' }}>
            <Typography variant="body1">{answerText}</Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Question;
