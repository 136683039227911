import React, { useEffect } from 'react';
import { useAuthentication } from 'api';

import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';
import SideDrawer from 'component/drawer';

interface ProtectedRouteProps {
  children: React.ReactNode;
  route: string;
}

const ProtectedRoute = ({ children, route }: ProtectedRouteProps) => {
  const { checkAuth } = useAuthentication();
  const [cookies] = useCookies(['refreshToken']);
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return cookies.refreshToken ? (
    <SideDrawer renderedComponent={children} selectedRoute={route} />
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoute;
