import { makeStyles } from '@mui/styles';

export const signUpStyle = makeStyles(() => ({
  paper: {},
  form: {
    width: '100%',
    marginTop: '10%',
  },
  container: {
    display: 'flex',
    height: '100vh',
  },
  formContainer: {
    flex: '1',
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})) as () => Record<string, string>;
