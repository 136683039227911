import { LoadingButton } from '@mui/lab';
import { Box, Pagination, Typography } from '@mui/material';
import Question from 'component/question';
import { QuestionType } from 'pages/ExamDetail/useExamDetail';
import React, { useEffect } from 'react';

interface ExamQuestionsProps {
  questions: QuestionType[];
  loading: boolean;
  generateQuestions: () => void;
}

const ExamQuestions = ({ questions, loading, generateQuestions }: ExamQuestionsProps) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const question = questions[currentPage - 1];

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [questions]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        flexDirection: 'column',
        alignItems: 'left',
      }}>
      {question ? (
        <>
          <Question
            key={question.id}
            questionText={question.QUESTION}
            answerText={question.ANSWER}
          />

          <Pagination
            count={questions.length}
            page={currentPage}
            onChange={handleChange}
            sx={{ width: '100%', mt: 6, mx: 'auto', display: 'flex', justifyContent: 'center' }}
          />
        </>
      ) : (
        <Typography variant="h5" sx={{ mt: 3 }}>
          No Questions
        </Typography>
      )}
      <LoadingButton
        variant="contained"
        loading={loading}
        sx={{ mt: 3 }}
        onClick={generateQuestions}>
        {questions.length > 0 ? 'Regenerate' : 'Generate'}
      </LoadingButton>
    </Box>
  );
};

export default ExamQuestions;
